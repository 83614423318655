import React, { Fragment, lazy, Suspense } from "react";
import logo from "./logo.svg";
import ReactDOM from "react-dom/client";
//import './App.css';
import "../src/Assets/main.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/bundle';

import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  Routes,
  RouterProvider,
  Navigate,
  Outlet,
} from "react-router-dom";
import Example from "./Pages/Loader/Examples";

const HomePage = lazy(() => import("./Pages/HomePage/HomePage.jsx"));
const Contact = lazy(() => import("./Pages/Contact/Contact.jsx"));
const EditBlog = lazy(() => import("./Pages/Blog/EditBlog/EditBlog.jsx"));
const AddBlog = lazy(() => import("./Pages/Blog/AddBlog/AddBlog.jsx"));
const Blog = lazy(() => import("./Pages/Blog/Blog/Blog.jsx"));
const SingleBlog = lazy(() => import("./Pages/Blog/SingleBlog/SingleBlog.jsx"));
const PublicRoutes = [
  { path: "/", component: HomePage, permission: ["admin", "visitor"] },
  { path: "/addblog", component: AddBlog, permission: ["admin", "visitor"] },
  { path: "/blogs", component: Blog, permission: ["admin", "visitor"]},
  { path: "/contact", component: Contact, permission: ["admin", "visitor"]},
  { path: "/blog/:id", component: SingleBlog, permission: ["admin", "visitor"]}
  ,
  { path: "/editblo/:id", component: EditBlog, permission: ["admin", "visitor"]}
];

const PrivateRoutes = [
  { path: "/", component: HomePage, permission: ["admin", "visitor"] },
];

const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};
const App = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Suspense fallback={<Example />}>
        <Routes>
          {PrivateRoutes.map((route, id) => {
            <Route
              path={route.path}
              key={id}
              element={
                <ProtectedRoute isAllowed={true}>
                  <route.component />
                </ProtectedRoute>
              }
            />;
          })}

          {PublicRoutes.map((route, id) => (
            <Route path={route.path} key={id} element={<route.component />} />
          ))}
        </Routes>
        </Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
