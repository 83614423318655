import {FETCH_BLOG,SET_BLOGS} from "../Action/blog";

const initState = [];

const blogReducer = (state = initState, action) => {
    const blogs = state;

    if (action.type === SET_BLOGS) {
        return {
      ...blogs,
      blogs: action.payload
    };
      }

      if (action.type === FETCH_BLOG) {
        return {
      ...blogs,
      blogs: action.payload
    };
      }

    

  return blogs;
};


export default blogReducer;


