import React, { Fragment } from "react";
import UseAnimations from 'react-useanimations';
import "./styles.css";
import loading1 from 'react-useanimations/lib/loading3';

const Example = () => (
  <Fragment>
  <div className="flex flex-col items-center justify-center bg-color2 hole">
    <UseAnimations  className="mb-4" strokeColor="#ffff" animation={loading1} size={72} />
    <p className="text-colorwhite thescribe text-5xl "><span> 😊 </span></p>
    <p className="text-colorwhite thescribe text-5xl ">Please wait...</p>

 </div>
  </Fragment>
);

export default Example;