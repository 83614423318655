import {CLEAR_PROFILE, FETCH_PROFILE, SET_PROFILE} from "../Action/login";

const initState = [];

const loginReducer = (state = initState, action) => {
    const profile = state;

    if (action.type === SET_PROFILE) {

        return {
      ...profile,
            profile: action.payload
    };
      }

      
    if (action.type === FETCH_PROFILE) {
        return {
          ...profile,
            profile: action.payload
        }
      }

    if (action.type === CLEAR_PROFILE) {
        return {};
      }

  return state;
};

export const selectAuth = state => state.loginReducer;

export default loginReducer;


