import React from "react";

export const SET_PROFILE = "SET_PROFILE";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

const fetchProfile = profile => ({
    type: FETCH_PROFILE,
    payload: profile
});

export const setProfileR = (profile) => {
    return dispatch => {
        dispatch({type: SET_PROFILE, payload: profile});
    };
};


export const clearProfile = () => {
    return dispatch => {
        dispatch({type: CLEAR_PROFILE})
    }
}
