import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-quill/dist/quill.snow.css';
import {Provider} from "react-redux";
import {load, save} from "redux-localstorage-simple";
import {applyMiddleware,createStore} from "redux";
import thunk from "redux-thunk";
import rootReducer from './Pages/Redux/Reducer/rootReducer';
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from '@react-oauth/google';

const store = createStore(
    rootReducer,
    load(),
    applyMiddleware(thunk, save())
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<GoogleOAuthProvider clientId="953567922669-45ls3pf73ids6m20tn2dk5t1tirbma3n.apps.googleusercontent.com">
    <Provider store={store}>
    <App />
    </Provider>
    </GoogleOAuthProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
