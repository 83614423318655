import Axios from "axios";
export const FETCH_BLOG = "FETCH_BLOG";
export const SET_BLOGS = "SET_BLOGS";

const instance=Axios.create();

const fetchBlogSuccess = blog => ({
    type: FETCH_BLOG,
    payload: blog
});

export const setBlog = blogs => ({
    type: SET_BLOGS,
    payload: blogs
});

export const fetchBlogs = ()=>{
return dispatch =>{
    instance.get("https://awura.spechelp.net/pullBlogs.php").then((res)=>{
  dispatch(fetchBlogSuccess(res.data));
    }).catch((e)=>{
        console.log("error");
    })
}

}