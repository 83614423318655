import loginReducer from "./loginReducer";
import blogReducer from "./blogReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    loginReducer:loginReducer,
    blogReducer:blogReducer
});

export default rootReducer;
